import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
 })
export class DatePickerComponent implements OnInit {

  @Output() datesChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() datepickerMode: string;
  @Input() bsValue = new Date();

  protected showPopup = false;
  protected showDate = true;
  protected showWeeks = false;

  protected dateMax = new Date();

  private date: Date = null;
  protected displayDate = '';
  constructor( ) { }

  public ngOnInit() {
    this.date = this.bsValue;
  }

  public ngOnChanges(changes: SimpleChanges) {
    // This prevents unneccesary triggers of actions
    if (changes.bsValue.previousValue && changes.bsValue.currentValue) {
      if (changes.bsValue.previousValue.toLocaleDateString() !== changes.bsValue.currentValue.toLocaleDateString()) {
        this.onDateChange(changes.bsValue.currentValue);
      }
    }
  }

  protected offClick(): void {
    this.showPopup = false;
  }

  protected onDateChange(date: Date): void {
    this.date = date;
    this.updateDisplayDate();
  }

  private updateDisplayDate(): void {
    this.datesChange.emit(this.date);
    this.showPopup = false;
    this.displayDate = UtilsService.dateTimeToString(this.date).slice(0, 10);
  }

}
