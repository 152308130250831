import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PointSampleQualityService {

  constructor (
    private httpClient: HttpClient,
  ) { }

  public getAveragesOnDate(date: string): Observable<Object> {
    return this.httpClient.get('/api/point_sample_quality/avg?date=' + date);
  }

  public getAveragesOnDateAndFreq(date: string, freq: string): Observable<Object> {
    return this.httpClient.get('/api/point_sample_quality/avg/freq/?freq=' + freq + '&date=' + date);
  }

  public getAveragesWithDelayOnDate(date: string): Observable<Object> {
    return this.httpClient.get('/api/point_sample_quality/avg/delay/?date=' + date)
  }

  public getAverageBySiteAndDate(siteIds: any[], date: string): Observable<Object> {
    let params = new HttpParams().set('date', date);

    siteIds.forEach(id => {
      params = params.append('siteId', id.toString());
    });
    return this.httpClient.get('api/point_sample_quality/avg/site', { params });
  }

  public getAverageBySiteAndFreqAndDate(siteIds: any[], freq: string, date: string): Observable<Object> {
    let params = new HttpParams().set('date', date);

    siteIds.forEach(id => {
      params = params.append('siteId', id.toString());
    });
    return this.httpClient.get('api/point_sample_quality/avg/site/freq/?freq=' + freq, { params });
  }

  public getBySiteAndDate(siteId, date: string): Observable<Object> {
    return this.httpClient.get('api/point_sample_quality/site/' + siteId + '?date=' + date)
  }

  

}

export interface IPointSampleQuality {
  id: {pointId: number; date: Date};
  completeness: number;
  reliability: number;
  quality: number;
  bb_point_id: number;
}

export interface AvgPointSampleQuality {
  building_quality_id: number;
  building_id: number;
  date: Date | string;
  completeness: number;
  reliability: number;
  quality: number;
  completeness_good: number;
  completeness_action_needed: number;
  completeness_under_action: number;
  completeness_make_active: number;
  reliability_good: number;
  reliability_action_needed: number;
  reliability_under_action: number;
  reliability_make_active: number;
  non_active: number;
  completenessRounded?: number;
  reliabilityRounded?: number;
  siteName?: string;
  data_contact_1?: string;
  data_contact_2?: string;
  customer_contact_1?: string;
  customer_contact_2?: string;
  cGood?: number;
  cActionNeeded?: number;
  cUnderAction?: number;
  cMakeActive?: number;
  rGood?: number;
  rActionNeeded?: number;
  rUnderAction?: number;
  rMakeActive?: number;
  nonActive?: number;
  data_contact_1_href?: string;
  data_contact_2_href?: string;
  customer_contact_1_href?: string;
  customer_contact_2_href?: string;
}