import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { FormsModule } from '@angular/forms';
import { DatepickerConfig, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { OffClickModule } from '../off-click/off-click.module';




@NgModule({
  declarations: [
    DatePickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DatepickerModule.forRoot(),
    TranslateModule,
    OffClickModule
  ],
  exports: [DatePickerComponent],
  providers: [
    DatepickerConfig,
    DatePipe
  ]
})
export class DatePickerModule { }
