import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface RuleTypeCauses {
  id: number,
  description: string,
}

export interface RuleTypeConsequenses {
  id: number,
  description: string,
}

export interface RuleTypeInfo {
  id: number,
  description: string,
  ruleTypeCauses: RuleTypeCauses[],
  ruleTypeConsequences: RuleTypeConsequenses[],
}

@Injectable({
  providedIn: 'root'
})
export class RuleTypeService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  public getAllRuleTypes(): Observable<RuleTypeInfo[]> {
    return this.httpClient.get<RuleTypeInfo[]>('/api/ruletypes/');
  }

  public getRuleTypeWithId(id: number): Observable<RuleTypeInfo> {
    return this.httpClient.get<RuleTypeInfo>('/api/ruletypes/' + id);
  }
}
