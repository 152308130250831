import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerContactsComponent } from './customer-contacts.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [CustomerContactsComponent],
  exports: [CustomerContactsComponent],
})
export class CustomerContactsModule { }
