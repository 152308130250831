import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RuleTypeService, RuleTypeInfo } from '../../services/rule-type.service';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './rule-type-details.component.html',
  styleUrls: ['./rule-type-details.component.scss']
})
export class RuleTypeDetailsComponent implements OnInit {

  private ruleTypeId: number;
  protected ruleTypeDetails: RuleTypeInfo = {
    id: 0,
    description: 'Bezig met laden',
    ruleTypeCauses: [],
    ruleTypeConsequences: [],
  };

  constructor(
    public modal: NgbActiveModal,
    private ruleTypeService: RuleTypeService,
  ) { }

  public ngOnInit(): void {
    this.ruleTypeService.getRuleTypeWithId(this.ruleTypeId)
        .subscribe(data => this.ruleTypeDetails = data);
  }
}
